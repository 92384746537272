<template>
  <v-progress-circular indeterminate color="primary" :size="size" :width="width"></v-progress-circular>
</template>
<script>
  export default {
    props: {
      size: {
        type: Number,
        required: false,
        default: 40,
      },
      width: {
        type: Number,
        required: false,
        default: 5,
      },
    },
  };
</script>
